/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
 
.ExpertPage .ContentPage .LogoExpert {
    max-width: 300px;
    border-radius: 20px;
}

@media (max-width: 600px) {
    .ExpertPage .ContentPage .LogoExpert {
        width: 100%;
    }
}

.ExpertPage .ContentPage .MediaArrow {
    position: absolute;
    top: 10px;
    right: 10px;
}

.ExpertPage .ContentPage .MediaIcon {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    background: white;
}

.ExpertPage .ContentPage .MediaIcon .MuiSvgIcon-root {
    font-size: 60px;
}

.ExpertPage .ContentPage .MediaIcon .MuiSvgIcon-root.TelegramIcon {
    position: relative;
    top: 11px;
    left: 7px;
    color: #30A8E0;
}

.ExpertPage .ContentPage .MediaIcon .MuiSvgIcon-root.GitHubIcon {
    position: relative;
    top: 10px;
    left: 10px;
    color: #171515;
}

.ExpertPage .ContentPage .MediaIcon .MuiSvgIcon-root.YouTubeIcon {
    position: relative;
    top: 11px;
    left: 10px;
    color: #FE0001;
}

.ExpertPage .ContentPage .MediaIcon .MuiSvgIcon-root.LanguageIcon {
    position: relative;
    top: 10px;
    left: 10px;
    color: #2191EB;
}

.ExpertPage .ContentPage .MediaIcon .MuiSvgIcon-root.CellTowerIcon {
    position: relative;
    top: 11px;
    left: 10px;
    color: #eb8921;
}
