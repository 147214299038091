/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
 
// BlockAbout
.HomePage .ContentPage .BlockAbout {
    background-color: #802aea0d;
    border-radius: 20px;
    padding: 80px 60px;
}

.ThemeDark .HomePage .ContentPage .BlockAbout {
    background-color: #cb8ff717;
}

@media (max-width: 1200px) {
    .HomePage .ContentPage .BlockAbout {
        padding: 40px 32px;
    }
}

// BlockCards
.HomePage .ContentPage .BlockCards .Logo {
    height: 35px;
    filter: brightness(1) saturate(1%);
}

.ThemeDark .HomePage .ContentPage .BlockCards .Logo {
    filter: contrast(0%) brightness(2);
}

// BlockPartners
.HomePage .ContentPage .BlockPartners {
    background-color: #f9f9f9;
    border-radius: 20px;
    padding: 40px;
}

@media (max-width: 1200px) {
    .HomePage .ContentPage .BlockPartners {
        padding: 32px;
    }
}

.ThemeDark .HomePage .ContentPage .BlockPartners {
    background-color: #1e1e1e;
}

.HomePage .ContentPage .BlockPartners .LaptopLottie {
    width: 100px;
    margin: 0 auto;
}
